import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const Active = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.5"
      cy="6.5"
      r="6.125"
      fill="#FCFCFC"
      stroke="#4383EF"
      strokeWidth="0.75"
    />
    <circle cx="6.5" cy="6.5" r="3.5" fill="#FF8513" />
  </Icon>
);

export const Inactive = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.5"
      cy="6.5"
      r="6.125"
      fill="#FCFCFC"
      stroke="#4383EF"
      strokeWidth="0.75"
    />
  </Icon>
);
