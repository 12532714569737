import React, { useContext, useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { useClickAway } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { PageSEO } from "../components/seo/page-seo";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Icons
import { Active, Inactive } from "../components/icons/active-view-icons";

const Page = styled.div`
  &.gallery-layout {
    & .content-grid {
      &.bottom-row {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-column-gap: 40px;

        @media (max-width: 1350px) {
          grid-column-gap: 20px;
        }

        @media (max-width: 768px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }

    & .single-artwork {
      &:nth-of-type(5n + 1) {
        grid-column: 1 / 7;

        @media (max-width: 768px) {
          grid-column: unset;
          margin: 0 auto 50px auto;
        }
      }

      &:nth-of-type(5n + 2) {
        grid-column: 8 / 12;

        @media (max-width: 768px) {
          grid-column: unset;
          margin: 0 auto 50px auto;
        }
      }

      &:nth-of-type(5n + 3) {
        grid-column: 3 / 10;

        margin: 50px 0 160px 0;

        @media (max-width: 768px) {
          grid-column: unset;
          margin: 0 auto 50px auto;
        }
      }

      &:nth-of-type(5n + 4) {
        grid-column: 1 / 4;

        @media (max-width: 768px) {
          grid-column: unset;
          margin: 0 auto 50px auto;
        }
      }

      &:nth-of-type(5n + 5) {
        grid-column: 6 / 12;

        margin: 230px 0 110px 0;

        @media (max-width: 768px) {
          grid-column: unset;
          margin: 0 auto 50px auto;
        }
      }
    }
  }

  &.grid-layout {
    & .content-grid {
      &.bottom-row {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 40px;

        @media (max-width: 1350px) {
          grid-column-gap: 20px;
        }

        @media (max-width: 768px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }

    & .single-artwork {
      grid-column: span 4;

      @media (max-width: 1100px) {
        grid-column: span 6;
      }

      @media (max-width: 768px) {
        grid-column: unset;
      }

      & img {
        width: 100%;
        height: 100%;

        &.landscape {
          object-fit: cover;
        }

        &.portrait {
          object-fit: contain;
          width: fit-content;
          margin: 0 auto;
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  & .content-grid {
    grid-column: 1 / 12;

    &.top-row {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 40px;

      @media (max-width: 1350px) {
        grid-column-gap: 20px;
      }
    }

    // @media (max-width: 1100px) {
    //   grid-column: 1 / 13;
    // }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;

      &.top-row {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }
`;

const Artwork = styled.div`
  position: relative;
  margin: 0 0 110px 0;

  & .artwork-text {
    max-width: 330px;
    margin: 10px auto 0 auto;

    @media (max-width: 768px) {
      margin: 5px auto 0 auto;
    }

    & h1,
    & p {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.05em;
    }

    & h1 {
      & em {
        font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
        font-style: italic;
      }
    }

    & p {
      margin: 0;
    }

    & .artwork-details {
      transition: 250ms opacity ease;
      opacity: 0;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  & img {
    z-index: 0;
  }

  &:hover {
    & .artwork-text {
      & .artwork-details {
        opacity: 1;
      }
    }

    & .fill {
      opacity: 1;
    }
  }

  & .inner-overview-image-container {
    position: relative;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    &.portrait {
      max-width: 80%;
      margin: 0 auto 50px auto;
    }
  }
`;

const DesktopFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 1100px) {
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  grid-column: 1 / 13;

  margin: 25px 0 20px 0;

  & button {
    margin: 0 15px 0 0;
    text-transform: capitalize;
    text-align: left;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    &:last-of-type {
      margin: 0;
    }

    & span {
      opacity: 0;
      transition: 250ms opacity ease;
    }

    &:hover,
    &.active-tag {
      & span {
        opacity: 1;
      }
    }

    & svg {
      margin: 0 7px 0 0;
    }

    &.view-all {
      @media (max-width: 1100px) {
        margin: 0 15px 0 0;
      }
    }
  }

  & .tags-container {
    grid-column: 2 / 12;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & .view-container {
      @media (max-width: 1350px) {
        display: none;
      }
    }
  }
`;

const MobileFilters = styled.div`
  margin: 0 0 20px 0;
  grid-column: 1 / 13;

  & .mobile-filters-container {
    & button {
      display: block;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;

      margin: 0 0 10px 0;
      text-transform: capitalize;
      text-align: left;

      color: #163262;

      & span {
        opacity: 0;
        transition: 250ms opacity ease;
      }

      &:hover,
      &.active-tag {
        & span {
          opacity: 1;
        }
      }

      & svg {
        margin: 0 7px 0 0;
      }
    }

    & .tags-container {
    }
  }

  &.visible {
    & .mobile-filters-container {
      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      background-color: #5694fd;

      z-index: 10;

      padding: 124px 40px 20px 40px;

      @media (max-width: 768px) {
        padding: 107px 20px 20px 20px;
      }

      & button {
        color: #163262;

        &.toggle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  & .toggle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }

  @media (max-width: 768px) {
    & .mobile-filters-container {
      & button {
        & span {
          display: none;
        }

        &.active-tag {
          & span {
            display: inline-block;
          }
        }
      }
    }
  }
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #4383ef;
  mix-blend-mode: multiply;
  transition: 250ms opacity ease;

  opacity: 0;
  z-index: 1;
`;

const Index = ({ data, location }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  // Tags and filters
  const [activeTag, setActiveTag] = useState(`all`);
  const [activeView, setActiveView] = useState(`gallery`);

  // Mobile Filters
  const [isMobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const mobileFiltersRef = useRef(null);

  useClickAway(mobileFiltersRef, () => {
    setMobileFiltersVisible(false);
  });

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1075px)");

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  const scrollToElement = element => {
    var rect = document.getElementById(element).getBoundingClientRect();
    var offsetTop = rect.top - 100;
    window.scrollTo(0, offsetTop);
  };

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.activeTag !== undefined) {
          setActiveTag(location.state.activeTag);

          if (location.state.artworkId !== undefined) {
            const scrollWithTimeout = setTimeout(() => {
              scrollToElement(location.state.artworkId);
            }, 50);
            return () => clearTimeout(scrollWithTimeout);
          }
        }

        if (location.state.activeView !== undefined) {
          setActiveView(location.state.activeView);

          if (location.state.artworkId !== undefined) {
            const scrollWithTimeout = setTimeout(() => {
              scrollToElement(location.state.artworkId);
            }, 50);
            return () => clearTimeout(scrollWithTimeout);
          }
        }
      }
    }
  }, [location]);

  useEffect(() => {
    setMobileFiltersVisible(false);
  }, [activeTag]);

  useEffect(() => {
    if (isMobileFiltersVisible === true) {
      document.body.classList.add(`mobile-filters-open`);
    } else {
      document.body.classList.remove(`mobile-filters-open`);
    }
  }, [isMobileFiltersVisible]);

  const cleanArtworks = data.prismicHomepage.data.artworks.filter(
    artwork => artwork.artwork.document !== null
  );

  const tags = Array.from(
    new Set(
      cleanArtworks
        .map(artwork =>
          artwork.artwork.document.data.tags
            .filter(tag => tag.tag !== null)
            .map(tag => tag.tag.toLowerCase())
            .flat()
        )
        .flat()
    )
  )
    .sort()
    .map((tag, index) => (
      <button
        key={`single_tag_button_${index}`}
        onClick={() => setActiveTag(tag)}
        className={activeTag === tag ? `active-tag` : ``}
      >
        <span>(</span>
        {tag}
        <span>)</span>
      </button>
    ));

  const filteredArtworks = cleanArtworks.filter(artwork => {
    if (activeTag === `all`) {
      return artwork;
    } else {
      const projectTags = artwork.artwork.document.data.tags
        .filter(tag => tag.tag !== null)
        .map(tag => tag.tag.toLowerCase());

      if (projectTags.includes(activeTag)) return artwork;
    }
  });

  const filteredArtworksLinks = filteredArtworks.map(
    link => link.artwork.document.url
  );

  const content = filteredArtworks.map((artwork, index) => {
    return (
      <Artwork
        id={artwork.artwork.document.id}
        key={`single_artwork_${index}_${artwork.artwork.document.id}`}
        className={`single-artwork ${
          artwork.artwork.document.data.images[0].image.fluid !== null
            ? ImageOrientation(artwork.artwork.document.data.images[0].image)
            : `landscape`
        }`}
      >
        <Link
          to={artwork.artwork.document.url}
          state={{
            activeTag: activeTag,
            activeView: activeView,
            artworks: filteredArtworksLinks,
          }}
        >
          {activeView === `overview` ? (
            <>
              {artwork.artwork.document.data.images.length >= 1 && (
                <>
                  {artwork.artwork.document.data.images[0].image.fluid !==
                    null && (
                    <AspectRatioImageContainer image={null} padding={`66.667`}>
                      <div className="inner-overview-image-container">
                        <img
                          className={ImageOrientation(
                            artwork.artwork.document.data.images[0].image
                          )}
                          srcSet={
                            artwork.artwork.document.data.images[0].image.fluid
                              .srcSetWebp
                          }
                          src={
                            artwork.artwork.document.data.images[0].image.fluid
                              .srcWebp
                          }
                          alt={
                            artwork.artwork.document.data.images[0].image.alt
                          }
                          loading={index <= 3 ? `eager` : `lazy`}
                        />

                        <Fill className="fill" />
                      </div>
                    </AspectRatioImageContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {artwork.artwork.document.data.images.length >= 1 && (
                <>
                  {artwork.artwork.document.data.images[0].image.fluid !==
                    null && (
                    <AspectRatioImageContainer
                      image={artwork.artwork.document.data.images[0].image}
                    >
                      <img
                        className={ImageOrientation(
                          artwork.artwork.document.data.images[0].image
                        )}
                        srcSet={
                          artwork.artwork.document.data.images[0].image.fluid
                            .srcSetWebp
                        }
                        src={
                          artwork.artwork.document.data.images[0].image.fluid
                            .srcWebp
                        }
                        alt={artwork.artwork.document.data.images[0].image.alt}
                        loading={index <= 3 ? `eager` : `lazy`}
                      />
                      <Fill className="fill" />
                    </AspectRatioImageContainer>
                  )}
                </>
              )}
            </>
          )}
        </Link>

        <div className="artwork-text">
          <div
            dangerouslySetInnerHTML={{
              __html: artwork.artwork.document.data.title.html,
            }}
          />

          <div className="artwork-details">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: artwork.artwork.document.data.subtitle.html,
              }}
            />

            <div
              className="details"
              dangerouslySetInnerHTML={{
                __html: artwork.artwork.document.data.details.html,
              }}
            />

            <div
              className="dimensions"
              dangerouslySetInnerHTML={{
                __html: artwork.artwork.document.data.dimensions.html,
              }}
            />
          </div>
        </div>
      </Artwork>
    );
  });

  return (
    <>
      <PageSEO
        title={data.prismicSeo.data.seo_title}
        description={null}
        image={null}
        url={`https://yinkashonibare.com/`}
      />
      <Page
        className={activeView === `gallery` ? `gallery-layout` : `grid-layout`}
      >
        <Grid>
          <div className="content-grid top-row">
            {isDesktop ? (
              <DesktopFilters>
                <button
                  onClick={() => setActiveTag(`all`)}
                  className={
                    activeTag === `all` ? `active-tag view-all` : ` view-all`
                  }
                >
                  <span>(</span>View All<span>)</span>
                </button>

                <div className="tags-container">
                  <div className="tags">{tags}</div>
                  <div className="view-container">
                    <button onClick={() => setActiveView(`gallery`)}>
                      {activeView === `gallery` ? <Active /> : <Inactive />}
                      Gallery View
                    </button>
                    <button onClick={() => setActiveView(`overview`)}>
                      {activeView === `overview` ? <Active /> : <Inactive />}
                      Overview
                    </button>
                  </div>
                </div>
              </DesktopFilters>
            ) : (
              <MobileFilters
                className={isMobileFiltersVisible ? `visible` : `hidden`}
                ref={mobileFiltersRef}
              >
                <button
                  className="toggle open"
                  onClick={() => {
                    setMobileFiltersVisible(!isMobileFiltersVisible);
                  }}
                >
                  Filter {isMobileFiltersVisible ? `-` : `+`}
                </button>

                {isMobileFiltersVisible && (
                  <RemoveScroll>
                    <div className="mobile-filters-container">
                      <button
                        className="toggle close"
                        onClick={() => {
                          setMobileFiltersVisible(false);
                        }}
                      >
                        Filter -
                      </button>

                      <button
                        onClick={() => setActiveTag(`all`)}
                        className={
                          activeTag === `all`
                            ? `active-tag view-all`
                            : ` view-all`
                        }
                      >
                        <span>(</span>View All<span>)</span>
                      </button>

                      <div className="tags-container">
                        <div className="tags">{tags}</div>
                      </div>
                    </div>
                  </RemoveScroll>
                )}
              </MobileFilters>
            )}
          </div>
        </Grid>

        <Grid>
          <div className="content-grid bottom-row">{content}</div>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicSeo {
      data {
        seo_title
      }
    }
    prismicHomepage {
      data {
        artworks {
          artwork {
            document {
              ... on PrismicArtwork {
                id
                url
                data {
                  images {
                    image {
                      alt
                      fluid {
                        srcWebp
                        srcSetWebp
                        aspectRatio
                      }
                      dimensions {
                        height
                        width
                      }
                    }
                  }
                  title {
                    html
                  }
                  subtitle {
                    html
                  }
                  tags {
                    tag
                  }
                  dimensions {
                    html
                  }
                  details {
                    html
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
      }
    }
  }
`;
